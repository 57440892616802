:root {
  --sitecolor: #42688E;
  --textcolor: #42688E;
  --titlecolor: #C4844F;
  --linkcolor: #42688E;
  --linkcolor-hov: #C4844F;
  --accentcolor: #42688E;
  --headerHeight: 204px;
  --gridGap: 50px;
}
@media (max-width: 1023px) {
  :root {
    --headerHeight: 125px;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #7A7A78;
  color: #fff;
  position: relative;
  padding: 10px 20px 10px 40px;
  line-height: 1.625;
  font-family: inherit;
  cursor: pointer;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  display: inline-block;
  transition: all 0.4s cubic-bezier(0.76, 0, 0.24, 1);
}
@media (hover: hover) and (pointer: fine) {
  .button:hover,
  .button:focus {
    background-color: var(--sitecolor);
  }
}
@media (max-width: 767px) {
  .button {
    padding-left: 31px;
  }
}
.button:after {
  content: '';
  width: 17px;
  height: 17px;
  position: absolute;
  left: 10px;
  top: 15px;
  background-size: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/button-arrow-right-white.svg);
  transition: all 0.4s cubic-bezier(0.76, 0, 0.24, 1);
}
@media (max-width: 767px) {
  .button:after {
    width: 13px;
    height: 13px;
  }
}
.linkarrow {
  line-height: 1.625;
  font-family: inherit;
  cursor: pointer;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  padding-left: 25px;
  display: inline-block;
}
@media (max-width: 767px) {
  .linkarrow {
    padding-left: 21px;
  }
}
.linkarrow:after {
  content: '';
  width: 17px;
  height: 17px;
  position: absolute;
  left: 0;
  top: 4px;
  background-size: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/nav-arrow-right.svg);
  transition: all 0.4s cubic-bezier(0.76, 0, 0.24, 1);
}
@media (max-width: 767px) {
  .linkarrow:after {
    width: 13px;
    height: 13px;
  }
}
.linkmail {
  --linkcolor: var(--sitecolor);
  --linkcolor-hov: #C4844F;
  line-height: 1.625;
  font-family: inherit;
  cursor: pointer;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  padding-left: 25px;
  display: inline-block;
}
@media (max-width: 767px) {
  .linkmail {
    padding-left: 21px;
  }
}
.linkmail:after {
  content: '';
  width: 17px;
  height: 17px;
  position: absolute;
  left: 0;
  top: 5px;
  background-size: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-mail.svg);
  transition: all 0.4s cubic-bezier(0.76, 0, 0.24, 1);
}
@media (max-width: 767px) {
  .linkmail:after {
    width: 13px;
    height: 13px;
  }
}
.linkextern {
  --linkcolor: #7A7A78;
  --linkcolor-hov: #C4844F;
  line-height: 1.625;
  font-family: inherit;
  cursor: pointer;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  display: inline-block;
}
.linkextern:after {
  content: '';
  display: inline-block;
  width: 17px;
  height: 17px;
  margin-left: 5px;
  left: 0;
  background-size: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-linkextern.svg);
  transition: all 0.4s cubic-bezier(0.76, 0, 0.24, 1);
}
@media (max-width: 767px) {
  .linkextern:after {
    width: 13px;
    height: 13px;
  }
}
.download {
  --linkcolor: #7A7A78;
  --linkcolor-hov: #C4844F;
  line-height: 1.625;
  font-family: inherit;
  cursor: pointer;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  display: inline-block;
}
.download:after {
  content: '';
  display: inline-block;
  width: 17px;
  height: 17px;
  margin-left: 5px;
  margin-bottom: -4px;
  left: 0;
  background-size: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-download.svg);
  transition: all 0.4s cubic-bezier(0.76, 0, 0.24, 1);
}
@media (max-width: 767px) {
  .download:after {
    width: 13px;
    height: 13px;
  }
}
/*# sourceMappingURL=./screen-medium.css.map */